<template>
  <div class="cancellation-penalty-sidebar">
    <div>
      <CollectPaymentNew
        :id="`operator-cancellation-penalty`"
        ref="collect-payment-new"
        v-bind="$attrs"
        :balance="amount"
        :default-selected-payment-method="PaymentMethodTypeKey.CreditCard"
        :operator-payment-profiles="operatorPaymentProfiles"
        :default-selected-card="defaultSelectedCard"
        :tooltip-text="tooltipText"
        :tooltip-style="{ 'white-space': 'pre-line' }"
        is-operator-cancel
        allow-hold-future-payment
        @selected-card="setPaymentProfile"
        @amount-updated="updateAmount"
        @new-card-selected="newCardSelected"
      ></CollectPaymentNew>
    </div>
    <div>
      <CRButton
        id="unassign-referral-save-btn"
        :loading="loading"
        class="unassign-referral-sidebar"
        :class="{
          'charge-btn': !isNewCardSelected,
          'action-btn': isNewCardSelected,
        }"
        :color="buttonColor"
        text-color="white"
        @click="submit"
      >
        {{ buttonLabel }}
      </CRButton>
    </div>
  </div>
</template>

<script>
import operatorCancel from '@/services/operatorCancel'
import { authComputed } from '@/state/helpers'
import { EventBus } from '@/utils/event-bus'
import CollectPaymentNew from '@/components/CollectPaymentNew.vue'
import { currencyFilter } from '@/utils/currency'
import { mapActions } from 'vuex'
import { getCardType } from '@/utils/creditCard'
import { PaymentMethodTypeKey } from '@/utils/enum'

export default {
  components: {
    CollectPaymentNew,
  },
  props: {
    reservationId: {
      type: Number,
      default: null,
    },
    referral: {
      type: Object,
      default: () => {},
    },
    ticketId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      minAmount: 0,
      amount: 0,
      operatorPaymentProfiles: [],
      operatorPaymentProfileId: null,
      loading: false,
      isNewCardSelected: false,
      defaultSelectedCard: null,
      tooltipText: `More than 30 days: No Fee
            30 days to 15 days: $250/vehicle/day
            14 days - 24 Hours: $500/vehicle/day
            Less than 24 hours: Minimum $750/vehicle/day
            (or full value of the trips)`,
      PaymentMethodTypeKey: PaymentMethodTypeKey,
    }
  },
  computed: {
    ...authComputed,
    holdPayment() {
      return this.$refs['collect-payment-new'].holdPayment
    },
    buttonLabel() {
      return `Charge ${currencyFilter(this.amount)}`
    },
    buttonColor() {
      return 'success'
    },
  },
  async mounted() {
    this.operatorPaymentProfileId = this.referral?.operatorCancellationSelectedPaymentMethodId
    await this.getOperatorPaymentProfiles()
    const foundCard = this.operatorPaymentProfiles.find(
      (profile) =>
        profile.operatorPaymentProfileId === this.operatorPaymentProfileId
    )
    if (foundCard) {
      this.defaultSelectedCard = foundCard
    }
    await this.getOperatorCancellationPenaltyInfo()
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    getCardType,
    newCardSelected(e) {
      this.isNewCardSelected = e
    },
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    async getOperatorCancellationPenaltyInfo() {
      if (!this.referral?.reservationId) {
        return
      }
      const payload = await operatorCancel.getOperatorCancellationPenaltyInfo(
        this.referral.reservationId
      )
      this.amount = payload?.data?.cancellationInfoDTO?.totalPenalty
      this.minAmount = this.amount
    },
    async getOperatorPaymentProfiles() {
      if (!this.referral?.companyId) {
        return
      }
      const payload = await this.$store.dispatch(
        'payments/getOperatorPaymentProfiles',
        this.referral.companyId
      )
      this.operatorPaymentProfiles = payload?.data?.operatorPaymentProfiles.map(
        (profile) => {
          return {
            ...profile,
            typeLabel: profile?.label,
          }
        }
      )
    },
    async chargeOperatorPenalty() {
      const officeNotes = this.$refs['collect-payment-new'].officeNotes
      const paymentNotes = this.$refs['collect-payment-new'].paymentNotes
      const payload = {
        reservationId: this.referral?.reservationId,
        cancellationPenaltyInput: this.amount,
        operatorPaymentProfileId: this.operatorPaymentProfileId,
        officeNotes: officeNotes,
        paymentNotes: paymentNotes,
        holdPayment: this.holdPayment,
      }
      await operatorCancel.operatorChargePenalty(payload).catch((error) => {
        this.loading = false
        this.showAlert({
          type: 'error',
          message: `Issue ${
            this.holdPayment ? 'holding' : 'charging'
          } payment for operator cancellation penalty.`,
        })
        return false
      })
    },
    setPaymentProfile(creditCard) {
      if (creditCard?.newCard) {
        this.newCard = creditCard.newCard
      } else {
        this.operatorPaymentProfileId = creditCard?.operatorPaymentProfileId
      }
    },
    updateAmount(newAmount) {
      this.amount = parseInt(newAmount)
    },
    async createOperatorPaymentProfile() {
      let payload = {
        companyId: 2,
        operatorCompanyId: this.referral.companyId,
        cardNumber: this.newCard.cardNumber,
        cardExpirationDate: this.newCard.expiration,
        expiration: this.newCard.expiration,
        cardCode: this.newCard.securityCode,
        accountHolderName: this.newCard.name,
        cardLabel: this.getCardType(this.newCard.cardNumber),
        useDefaultPaymentGateway: true,
        customerId: null,
        address: this.newCard.address,
        showOnCharterUp: false,
      }
      const response = await this.$store
        .dispatch('payments/createOperatorPaymentProfile', payload)
        .catch((error) => {
          this.loading = false
          this.showAlert({
            type: 'error',
            message: 'Could not create card with given information.',
          })
          return false
        })
      return response?.data?.operatorPaymentProfileId
    },
    async submit() {
      this.loading = true
      if (this.holdPayment) {
        await this.chargeOperatorPenalty()
      } else {
        if (this.isNewCardSelected) {
          if (!this.$refs['collect-payment-new'].validateCreditCardSelector()) {
            this.showAlert({
              type: 'error',
              message: 'Invalid credit card information.',
            })
            this.loading = false
            return
          }
          const newOperatorPaymentProfileId = await this.createOperatorPaymentProfile()
          await this.getOperatorPaymentProfiles()
          if (newOperatorPaymentProfileId) {
            const foundCard = this.operatorPaymentProfiles.find(
              (profile) =>
                profile.operatorPaymentProfileId === newOperatorPaymentProfileId
            )
            if (foundCard) {
              this.defaultSelectedCard = foundCard
            }
          }
          this.loading = false
          return
        }
        if (!this.operatorPaymentProfileId) {
          this.showAlert({
            type: 'error',
            message: 'Must select credit card.',
          })
          this.loading = false
          return
        }
        await this.chargeOperatorPenalty()
      }
      EventBus.$emit('refresh-detail')
      EventBus.$emit('refresh-reservation-detail')
      EventBus.$emit('refresh-reservation-payments')
      this.loading = false
      this.close()
    },
  },
}
</script>
<style lang="scss" scoped>
.unassign-referral-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px 40px 0 40px;
  }
}

.action-btn {
  display: flex;
  position: fixed;
  flex-direction: column;
  font-size: 18px;
  bottom: 0;
  width: 500px !important;
  height: 71px !important;
  padding: 24px 0;
  width: inherit;
  border-radius: 0;
  background: $primary;
  z-index: 3;
}

.charge-btn {
  display: flex;
  position: fixed;
  flex-direction: column;
  background: $success;
  font-size: 18px;
  color: white;
  bottom: 0;
  padding: 24px 0;
  width: 500px !important;
  height: 71px !important;
  text-align: center;
  font-weight: bold;
  transition: background-color 0.2s ease-in-out;
  border-radius: 0;
  z-index: 3;
}

::v-deep .accent--text {
  color: $blue !important;
}
</style>
